
<template>
    <div class="container-fluid">
      <Breadcrumbs main="บันทึกข้อมูลการตรวจสอบผู้ตรวจสภาพรถ"/>

      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
          <div class="card">
              <div class="card-header">
                <h5>กรุณากรอกข้อมูลให้ครบถ้วน ก่อนกดบันทึก !</h5>
              </div>
              <div class="form theme-form">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <div class="mb-2 row">
                      <label class="col-sm-4 col-form-label">ประเภทการตรวจสอบ</label>
                        <div class="col-sm-8">
                            <select class="form-select" v-model="type">
                                <option value="1" selected="selected">การตรวจสอบปกติ</option>
                                <option value="2">การตรวจสอบพิเศษ</option>
                                <option value="3">การตรวจสอบแก้ไข</option>
                            </select>
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <div class="col-sm-8 offset-sm-4">
                            <div class="ribbon-wrapper card">
                                <div class="card-body">
                                    <div class="ribbon ribbon-bookmark ribbon-info">หมายเหตุ</div>
                                    <p>การตรวจสอบปกติ หมายถึง การตรวจสอบการดำเนินการของสถานตรวจสภาพรถกรณีทั่วไป ที่ต้องดำเนินการตรวจทุก 3 เดือน
                                    การตรวจพิเศษ หมายถึง การตรวจสอบที่ไม่อยู่ในรอบการตรวจสอบปกติ การตรวจของเจ้าหน้าที่จากส่วนกลาง หรือกรณีมีข้อร้องเรียนหรือข้อสงสัยว่ามีการดำเนินการที่ฝ่าฝืนกฎหมาย
                                    การตรวจสอบแก้ไข หมายถึง การตรวจสอบในกรณีที่ผู้ได้รับอนุญาตได้แก้ไขข้อบกพร่องเสร็จแล้ว</p>
                                </div>
                            </div>
                        </div>
                      </div>
                      <div class="mb-2 row">
                      <label class="col-sm-4 col-form-label">ใบอนุญาตที่ / สถานตรวจสภาพชื่อ / ชื่อผู้ได้รับใบอนุญาต</label>
                        <div class="col-sm-8">
                          <multiselect :options="tro_name_id_value" :searchable="true" v-model="users_5" :custom-label="tro_name_id_select" placeholder="เลือกชื่อสถานตรวจสภาพรถและเลขที่ใบอนุญาตจัดตั้ง" />
                        </div>
                      </div>
                      
                      <div class="mb-2 row">
                        <label class="col-sm-12 col-form-label"><b>1. ได้รับอนุญาตให้ตรวจสภาพตามประเภทและขนาดน้ำหนัก ดังนี้</b></label>
                      </div>
                      <div class="mb-2 row">
                      <label class="col-sm-4 col-form-label">ได้รับอนุญาตให้ตรวจสภาพตามประเภทและขนาดน้ำหนัก</label>
                        <div class="col-sm-8">
                          <select class="form-select" v-model="data_1">
                                <option value="">กรุณาเลือก</option>
                                <option value="รถตามกฎหมายว่าด้วยการขนส่งทางบกและรถตามกฎหมายว่าด้วยรถยนต์ทุกประเภทและทุกขนาดน้ำหนัก">รถตามกฎหมายว่าด้วยการขนส่งทางบกและรถตามกฎหมายว่าด้วยรถยนต์ทุกประเภทและทุกขนาดน้ำหนัก</option>
                                <option value="รถตามกฎหมายว่าด้วยรถยนต์ รถยนต์ขนาดน้ำหนักรถเปล่าไม่เกิน 2200 กิโลกรัม และรถจักรยานยนต์">รถตามกฎหมายว่าด้วยรถยนต์ รถยนต์ขนาดน้ำหนักรถเปล่าไม่เกิน 2200 กิโลกรัม และรถจักรยานยนต์</option>
                                <option value="รถตามกฎหมายว่าด้วยรถยนต์ รถจักรยานยนต์">รถตามกฎหมายว่าด้วยรถยนต์ รถจักรยานยนต์</option>
                            </select>
                        </div>
                      </div>
                      <div class="mb-2 row">
                      <label class="col-sm-4 col-form-label">ครั้งสุดท้ายที่ตรวจสอบ</label>
                        <div class="col-sm-4">
                          <VueDatePicker locale="th" v-model="data_2" auto-apply :max-date="new Date()" :enable-time-picker="false"/>
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <u class="col-sm-12 col-form-label"><h5>รายการตรวจ</h5></u>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-12 col-form-label"><b>2. อาคารสถานที่</b></label>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-4 col-form-label">อาคาร</label>
                        <div class="col-sm-8">
                            <div class="col">
                                <div class="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                    <div class="radio radio-primary">
                                        <input id="radioinline3" type="radio" v-model="data_3" value="ถูกต้อง" checked>
                                        <label class="mb-0" for="radioinline3">ถูกต้อง</label>
                                    </div>
                                    <div class="radio radio-primary">
                                        <input id="radioinline4" type="radio" v-model="data_3" value="บกพร่อง">
                                        <label class="mb-0" for="radioinline4">บกพร่อง</label>
                                    </div>
                                    <div class="radio radio-primary">
                                        <input id="radioinline5" type="radio" v-model="data_3" value="มีการเปลี่ยนแปลง">
                                        <label class="mb-0" for="radioinline5">มีการเปลี่ยนแปลง</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-4 col-form-label">พื้นที่ตรวจสภาพรถ</label>
                        <div class="col-sm-8">
                            <div class="col">
                                <div class="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                    <div class="radio radio-primary">
                                        <input id="radioinline6" type="radio" v-model="data_4" value="ถูกต้อง" checked>
                                        <label class="mb-0" for="radioinline6">ถูกต้อง</label>
                                    </div>
                                    <div class="radio radio-primary">
                                        <input id="radioinline7" type="radio" v-model="data_4" value="บกพร่อง">
                                        <label class="mb-0" for="radioinline7">บกพร่อง</label>
                                    </div>
                                    <div class="radio radio-primary">
                                        <input id="radioinline8" type="radio" v-model="data_4" value="มีการเปลี่ยนแปลง">
                                        <label class="mb-0" for="radioinline8">มีการเปลี่ยนแปลง</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-4 col-form-label">ลานจอดรถและพื้นที่สำหรับรถรอเข้าตรวจสภาพ</label>
                        <div class="col-sm-8">
                            <div class="col">
                                <div class="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                    <div class="radio radio-primary">
                                        <input id="radioinline9" type="radio" v-model="data_5" value="ถูกต้อง" checked>
                                        <label class="mb-0" for="radioinline9">ถูกต้อง</label>
                                    </div>
                                    <div class="radio radio-primary">
                                        <input id="radioinline10" type="radio" v-model="data_5" value="บกพร่อง">
                                        <label class="mb-0" for="radioinline10">บกพร่อง</label>
                                    </div>
                                    <div class="radio radio-primary">
                                        <input id="radioinline11" type="radio" v-model="data_5" value="มีการเปลี่ยนแปลง">
                                        <label class="mb-0" for="radioinline11">มีการเปลี่ยนแปลง</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-4 col-form-label">การใช้อาคารสถานที่ทำกิจการอื่นที่ไม่ถูกต้อง</label>
                        <div class="col-sm-8">
                            <div class="col">
                                <div class="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                    <div class="radio radio-primary">
                                        <input id="radioinline12" type="radio" v-model="data_6" value="มี" checked>
                                        <label class="mb-0" for="radioinline12">มี</label>
                                    </div>
                                    <div class="radio radio-primary">
                                        <input id="radioinline13" type="radio" v-model="data_6" value="ไม่มี">
                                        <label class="mb-0" for="radioinline13">ไม่มี</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-12 col-form-label"><b>3.ข้อความหรือเครื่องหมายที่ต้องแสดง (ป้ายต่าง ๆ)</b></label>
                      </div>
                        <div class="mb-2 row">
                        <label class="col-sm-4 col-form-label">ป้ายชื่อสถานตรวจสภาพรถ</label>
                        <div class="col-sm-8">
                            <div class="col">
                                <div class="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                    <div class="radio radio-primary">
                                        <input id="radioinline14" type="radio" v-model="data_7" value="ถูกต้อง" checked>
                                        <label class="mb-0" for="radioinline14">ถูกต้อง</label>
                                    </div>
                                    <div class="radio radio-primary">
                                        <input id="radioinline15" type="radio" v-model="data_7" value="บกพร่อง">
                                        <label class="mb-0" for="radioinline15">บกพร่อง</label>
                                    </div>
                                    <div class="radio radio-primary">
                                        <input id="radioinline16" type="radio" v-model="data_7" value="มีการเปลี่ยนแปลง">
                                        <label class="mb-0" for="radioinline16">มีการเปลี่ยนแปลง</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div> <div class="mb-2 row">
                        <label class="col-sm-4 col-form-label">เครื่องหมายสัญลักษณ์ประจำสถานตรวจสภาพรถ</label>
                        <div class="col-sm-8">
                            <div class="col">
                                <div class="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                    <div class="radio radio-primary">
                                        <input id="radioinline17" type="radio" v-model="data_8" value="ถูกต้อง" checked>
                                        <label class="mb-0" for="radioinline17">ถูกต้อง</label>
                                    </div>
                                    <div class="radio radio-primary">
                                        <input id="radioinline18" type="radio" v-model="data_8" value="บกพร่อง">
                                        <label class="mb-0" for="radioinline18">บกพร่อง</label>
                                    </div>
                                    <div class="radio radio-primary">
                                        <input id="radioinline19" type="radio" v-model="data_8" value="มีการเปลี่ยนแปลง">
                                        <label class="mb-0" for="radioinline19">มีการเปลี่ยนแปลง</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div> <div class="mb-2 row">
                        <label class="col-sm-4 col-form-label">ป้ายแสดงประเภทรถที่ให้บริการตรวจสภาพ</label>
                        <div class="col-sm-8">
                            <div class="col">
                                <div class="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                    <div class="radio radio-primary">
                                        <input id="radioinline20" type="radio" v-model="data_9" value="ถูกต้อง" checked>
                                        <label class="mb-0" for="radioinline20">ถูกต้อง</label>
                                    </div>
                                    <div class="radio radio-primary">
                                        <input id="radioinline21" type="radio" v-model="data_9" value="บกพร่อง">
                                        <label class="mb-0" for="radioinline21">บกพร่อง</label>
                                    </div>
                                    <div class="radio radio-primary">
                                        <input id="radioinline22" type="radio" v-model="data_9" value="มีการเปลี่ยนแปลง">
                                        <label class="mb-0" for="radioinline22">มีการเปลี่ยนแปลง</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div> <div class="mb-2 row">
                        <label class="col-sm-4 col-form-label">ป้ายแสดงอัตราค่าบริการตรวจสภาพรถ</label>
                        <div class="col-sm-8">
                            <div class="col">
                                <div class="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                    <div class="radio radio-primary">
                                        <input id="radioinline23" type="radio" v-model="data_10" value="ถูกต้อง" checked>
                                        <label class="mb-0" for="radioinline23">ถูกต้อง</label>
                                    </div>
                                    <div class="radio radio-primary">
                                        <input id="radioinline24" type="radio" v-model="data_10" value="บกพร่อง">
                                        <label class="mb-0" for="radioinline24">บกพร่อง</label>
                                    </div>
                                    <div class="radio radio-primary">
                                        <input id="radioinline25" type="radio" v-model="data_10" value="มีการเปลี่ยนแปลง">
                                        <label class="mb-0" for="radioinline25">มีการเปลี่ยนแปลง</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div> <div class="mb-2 row">
                        <label class="col-sm-4 col-form-label">ป้ายแสดงวันและเวลาทำการปกติ</label>
                        <div class="col-sm-8">
                            <div class="col">
                                <div class="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                    <div class="radio radio-primary">
                                        <input id="radioinline26" type="radio" v-model="data_11" value="ถูกต้อง" checked>
                                        <label class="mb-0" for="radioinline26">ถูกต้อง</label>
                                    </div>
                                    <div class="radio radio-primary">
                                        <input id="radioinline27" type="radio" v-model="data_11" value="บกพร่อง">
                                        <label class="mb-0" for="radioinline27">บกพร่อง</label>
                                    </div>
                                    <div class="radio radio-primary">
                                        <input id="radioinline28" type="radio" v-model="data_11" value="มีการเปลี่ยนแปลง">
                                        <label class="mb-0" for="radioinline28">มีการเปลี่ยนแปลง</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div> <div class="mb-2 row">
                        <label class="col-sm-4 col-form-label">ป้ายแสดง พื้นที่ตรวจสภาพรถ บุคคลที่ไม่เกี่ยวข้องห้ามเข้า</label>
                        <div class="col-sm-8">
                            <div class="col">
                                <div class="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                    <div class="radio radio-primary">
                                        <input id="radioinline29" type="radio" v-model="data_12" value="ถูกต้อง" checked>
                                        <label class="mb-0" for="radioinline29">ถูกต้อง</label>
                                    </div>
                                    <div class="radio radio-primary">
                                        <input id="radioinline30" type="radio" v-model="data_12" value="บกพร่อง">
                                        <label class="mb-0" for="radioinline30">บกพร่อง</label>
                                    </div>
                                    <div class="radio radio-primary">
                                        <input id="radioinline31" type="radio" v-model="data_12" value="มีการเปลี่ยนแปลง">
                                        <label class="mb-0" for="radioinline31">มีการเปลี่ยนแปลง</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-12 col-form-label"><b>4.เครื่องตรวจสภาพ อุปกรณ์และสิ่งอำนวยความสะดวกในการตรวจสภาพรถ (ชนิด/รุ่น/หมายเลข)</b></label>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-4 col-form-label">เครื่องทดสอบห้ามล้อ</label>
                        <div class="col-sm-8">
                            <div class="col">
                                <div class="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                    <div class="radio radio-primary">
                                        <input id="radioinline32" type="radio" v-model="data_13" value="ถูกต้อง" checked>
                                        <label class="mb-0" for="radioinline32">ถูกต้อง</label>
                                    </div>
                                    <div class="radio radio-primary">
                                        <input id="radioinline33" type="radio" v-model="data_13" value="บกพร่อง">
                                        <label class="mb-0" for="radioinline33">บกพร่อง</label>
                                    </div>
                                    <div class="radio radio-primary">
                                        <input id="radioinline34" type="radio" v-model="data_13" value="มีการเปลี่ยนแปลง">
                                        <label class="mb-0" for="radioinline34">มีการเปลี่ยนแปลง</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-4 col-form-label">เครื่องทดสอบศูนย์ล้อ</label>
                        <div class="col-sm-8">
                            <div class="col">
                                <div class="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                    <div class="radio radio-primary">
                                        <input id="radioinline35" type="radio" v-model="data_14" value="ถูกต้อง" checked>
                                        <label class="mb-0" for="radioinline35">ถูกต้อง</label>
                                    </div>
                                    <div class="radio radio-primary">
                                        <input id="radioinline36" type="radio" v-model="data_14" value="บกพร่อง">
                                        <label class="mb-0" for="radioinline36">บกพร่อง</label>
                                    </div>
                                    <div class="radio radio-primary">
                                        <input id="radioinline37" type="radio" v-model="data_14" value="มีการเปลี่ยนแปลง">
                                        <label class="mb-0" for="radioinline37">มีการเปลี่ยนแปลง</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-4 col-form-label">เครื่องทดสอบโคมไฟ้หน้า</label>
                        <div class="col-sm-8">
                            <div class="col">
                                <div class="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                    <div class="radio radio-primary">
                                        <input id="radioinline38" type="radio" v-model="data_15" value="ถูกต้อง" checked>
                                        <label class="mb-0" for="radioinline38">ถูกต้อง</label>
                                    </div>
                                    <div class="radio radio-primary">
                                        <input id="radioinline39" type="radio" v-model="data_15" value="บกพร่อง">
                                        <label class="mb-0" for="radioinline39">บกพร่อง</label>
                                    </div>
                                    <div class="radio radio-primary">
                                        <input id="radioinline40" type="radio" v-model="data_15" value="มีการเปลี่ยนแปลง">
                                        <label class="mb-0" for="radioinline40">มีการเปลี่ยนแปลง</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-4 col-form-label">เครื่องวัดควันดำ</label>
                        <div class="col-sm-8">
                            <div class="col">
                                <div class="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                    <div class="radio radio-primary">
                                        <input id="radioinline41" type="radio" v-model="data_16" value="ถูกต้อง" checked>
                                        <label class="mb-0" for="radioinline41">ถูกต้อง</label>
                                    </div>
                                    <div class="radio radio-primary">
                                        <input id="radioinline42" type="radio" v-model="data_16" value="บกพร่อง">
                                        <label class="mb-0" for="radioinline42">บกพร่อง</label>
                                    </div>
                                    <div class="radio radio-primary">
                                        <input id="radioinline43" type="radio" v-model="data_16" value="มีการเปลี่ยนแปลง">
                                        <label class="mb-0" for="radioinline43">มีการเปลี่ยนแปลง</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-4 col-form-label">เครื่องวัดระดับเสียง</label>
                        <div class="col-sm-8">
                            <div class="col">
                                <div class="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                    <div class="radio radio-primary">
                                        <input id="radioinline44" type="radio" v-model="data_17" value="ถูกต้อง" checked>
                                        <label class="mb-0" for="radioinline44">ถูกต้อง</label>
                                    </div>
                                    <div class="radio radio-primary">
                                        <input id="radioinline45" type="radio" v-model="data_17" value="บกพร่อง">
                                        <label class="mb-0" for="radioinline45">บกพร่อง</label>
                                    </div>
                                    <div class="radio radio-primary">
                                        <input id="radioinline46" type="radio" v-model="data_17" value="มีการเปลี่ยนแปลง">
                                        <label class="mb-0" for="radioinline46">มีการเปลี่ยนแปลง</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-4 col-form-label">เครื่องวัดความเร็วรอบเครื่องยนต์</label>
                        <div class="col-sm-8">
                            <div class="col">
                                <div class="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                    <div class="radio radio-primary">
                                        <input id="radioinline47" type="radio" v-model="data_18" value="ถูกต้อง" checked>
                                        <label class="mb-0" for="radioinline47">ถูกต้อง</label>
                                    </div>
                                    <div class="radio radio-primary">
                                        <input id="radioinline48" type="radio" v-model="data_18" value="บกพร่อง">
                                        <label class="mb-0" for="radioinline48">บกพร่อง</label>
                                    </div>
                                    <div class="radio radio-primary">
                                        <input id="radioinline49" type="radio" v-model="data_18" value="มีการเปลี่ยนแปลง">
                                        <label class="mb-0" for="radioinline49">มีการเปลี่ยนแปลง</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-4 col-form-label">เครื่องวิเคราะห์ก๊าซ</label>
                        <div class="col-sm-8">
                            <div class="col">
                                <div class="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                    <div class="radio radio-primary">
                                        <input id="radioinline50" type="radio" v-model="data_19" value="ถูกต้อง" checked>
                                        <label class="mb-0" for="radioinline50">ถูกต้อง</label>
                                    </div>
                                    <div class="radio radio-primary">
                                        <input id="radioinline51" type="radio" v-model="data_19" value="บกพร่อง">
                                        <label class="mb-0" for="radioinline51">บกพร่อง</label>
                                    </div>
                                    <div class="radio radio-primary">
                                        <input id="radioinline52" type="radio" v-model="data_19" value="มีการเปลี่ยนแปลง">
                                        <label class="mb-0" for="radioinline52">มีการเปลี่ยนแปลง</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-4 col-form-label">เครื่องวัดก๊าซรั่ว</label>
                        <div class="col-sm-8">
                            <div class="col">
                                <div class="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                    <div class="radio radio-primary">
                                        <input id="radioinline53" type="radio" v-model="data_20" value="ถูกต้อง" checked>
                                        <label class="mb-0" for="radioinline53">ถูกต้อง</label>
                                    </div>
                                    <div class="radio radio-primary">
                                        <input id="radioinline54" type="radio" v-model="data_20" value="บกพร่อง">
                                        <label class="mb-0" for="radioinline54">บกพร่อง</label>
                                    </div>
                                    <div class="radio radio-primary">
                                        <input id="radioinline55" type="radio" v-model="data_20" value="มีการเปลี่ยนแปลง">
                                        <label class="mb-0" for="radioinline55">มีการเปลี่ยนแปลง</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-4 col-form-label">เครื่องวัดความเข้มของฟิล์มกรองแสง</label>
                        <div class="col-sm-8">
                            <div class="col">
                                <div class="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                    <div class="radio radio-primary">
                                        <input id="radioinline56" type="radio" v-model="data_21" value="ถูกต้อง" checked>
                                        <label class="mb-0" for="radioinline56">ถูกต้อง</label>
                                    </div>
                                    <div class="radio radio-primary">
                                        <input id="radioinline57" type="radio" v-model="data_21" value="บกพร่อง">
                                        <label class="mb-0" for="radioinline57">บกพร่อง</label>
                                    </div>
                                    <div class="radio radio-primary">
                                        <input id="radioinline58" type="radio" v-model="data_21" value="มีการเปลี่ยนแปลง">
                                        <label class="mb-0" for="radioinline58">มีการเปลี่ยนแปลง</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-4 col-form-label">เครื่องยกรถ/บ่อตรวจสภาพรถ</label>
                        <div class="col-sm-8">
                            <div class="col">
                                <div class="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                    <div class="radio radio-primary">
                                        <input id="radioinline59" type="radio" v-model="data_22" value="ถูกต้อง" checked>
                                        <label class="mb-0" for="radioinline59">ถูกต้อง</label>
                                    </div>
                                    <div class="radio radio-primary">
                                        <input id="radioinline60" type="radio" v-model="data_22" value="บกพร่อง">
                                        <label class="mb-0" for="radioinline60">บกพร่อง</label>
                                    </div>
                                    <div class="radio radio-primary">
                                        <input id="radioinline61" type="radio" v-model="data_22" value="มีการเปลี่ยนแปลง">
                                        <label class="mb-0" for="radioinline61">มีการเปลี่ยนแปลง</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-4 col-form-label">กระจกเงาสะท้อนภาพ</label>
                        <div class="col-sm-8">
                            <div class="col">
                                <div class="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                    <div class="radio radio-primary">
                                        <input id="radioinline62" type="radio" v-model="data_23" value="ถูกต้อง" checked>
                                        <label class="mb-0" for="radioinline62">ถูกต้อง</label>
                                    </div>
                                    <div class="radio radio-primary">
                                        <input id="radioinline63" type="radio" v-model="data_23" value="บกพร่อง">
                                        <label class="mb-0" for="radioinline63">บกพร่อง</label>
                                    </div>
                                    <div class="radio radio-primary">
                                        <input id="radioinline64" type="radio" v-model="data_23" value="มีการเปลี่ยนแปลง">
                                        <label class="mb-0" for="radioinline64">มีการเปลี่ยนแปลง</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-4 col-form-label">เครื่องดับเพลิง</label>
                        <div class="col-sm-8">
                            <div class="col">
                                <div class="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                    <div class="radio radio-primary">
                                        <input id="radioinline65" type="radio" v-model="data_24" value="ถูกต้อง" checked>
                                        <label class="mb-0" for="radioinline65">ถูกต้อง</label>
                                    </div>
                                    <div class="radio radio-primary">
                                        <input id="radioinline66" type="radio" v-model="data_24" value="บกพร่อง">
                                        <label class="mb-0" for="radioinline66">บกพร่อง</label>
                                    </div>
                                    <div class="radio radio-primary">
                                        <input id="radioinline67" type="radio" v-model="data_24" value="มีการเปลี่ยนแปลง">
                                        <label class="mb-0" for="radioinline67">มีการเปลี่ยนแปลง</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-4 col-form-label">คอมพิวเตอร์และอุปกรณ์เชื่อมต่อระบบสารสนเทศ</label>
                        <div class="col-sm-8">
                            <div class="col">
                                <div class="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                    <div class="radio radio-primary">
                                        <input id="radioinline68" type="radio" v-model="data_25" value="ถูกต้อง" checked>
                                        <label class="mb-0" for="radioinline68">ถูกต้อง</label>
                                    </div>
                                    <div class="radio radio-primary">
                                        <input id="radioinline69" type="radio" v-model="data_25" value="บกพร่อง">
                                        <label class="mb-0" for="radioinline69">บกพร่อง</label>
                                    </div>
                                    <div class="radio radio-primary">
                                        <input id="radioinline70" type="radio" v-model="data_25" value="มีการเปลี่ยนแปลง">
                                        <label class="mb-0" for="radioinline70">มีการเปลี่ยนแปลง</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-4 col-form-label">ระบบโทรทัศน์วงจรปิด (CCTV)</label>
                        <div class="col-sm-8">
                            <div class="col">
                                <div class="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                    <div class="radio radio-primary">
                                        <input id="radioinline71" type="radio" v-model="data_26" value="ถูกต้อง" checked>
                                        <label class="mb-0" for="radioinline71">ถูกต้อง</label>
                                    </div>
                                    <div class="radio radio-primary">
                                        <input id="radioinline72" type="radio" v-model="data_26" value="บกพร่อง">
                                        <label class="mb-0" for="radioinline72">บกพร่อง</label>
                                    </div>
                                    <div class="radio radio-primary">
                                        <input id="radioinline73" type="radio" v-model="data_26" value="มีการเปลี่ยนแปลง">
                                        <label class="mb-0" for="radioinline73">มีการเปลี่ยนแปลง</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-12 col-form-label"><b>5. บุคลากรที่ปฏิบัติงาน</b></label>
                      </div>
                      <div class="card-block row">
                        <div class="col-sm-12 col-lg-12 col-xl-12">
                          
                            <div class="table-responsive">
                                <table class="table table-bordered checkbox-td-width">
                                    <thead>
                                        <tr>
                                            <th scope="col">ผู้ควบคุมการตรวจสภาพรถ</th>
                                            <th scope="col">สถานะ</th>
                                            <th scope="col">การดำเนินการตรวจสภาพรถ</th>
                                            <th scope="col">การวินิฉัยตรวจสภาพรถ</th>
                                            <th scope="col">จัดการ</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                      <tr v-for="(row , id) in rowsDT" :key="id">
                                        <td><select class="form-select" v-model="row.controller" v-html="selectDT"></select></td>
                                        <td><select class="form-select" v-model="row.statusex"><option value="">กรุณาเลือก</option><option value="1">อยู่</option><option value="0">ไม่อยู่</option></select></td>
                                        <td><select class="form-select" v-model="row.actuation"><option value="">กรุณาเลือก</option><option value="1">การอำนวยความสะดวกให้เจ้าหน้าที่ในการตรวจสอบ</option><option value="2">การแสดงบัตรประจำตัวของผู้ปฏิบัติงาน</option><option value="3">การแต่งกาย ความประพฤติ กริยา มารยาทของผู้ปฏิบัติงาน</option><option value="4">การจัดส่งรายงานการตรวจสภาพรถตามระยะเวลา</option><option value="5">แนวเส้นกำหนดพื้นที่ต่าง ๆ มีความชัดเจน</option><option value="6">ความสะอาด เรียบร้อยอาคารสถานที่</option><option value="7">การบำรุงรักษาเครื่องตรวจสภาพรถ</option><option value="8">การเตรียมความพร้อมใช้่งานของเครื่องตรวจสภาพรถ</option></select></td>
                                        <td><select class="form-select" v-model="row.diagnosis"><option value="">กรุณาเลือก</option><option value="1">ถูกต้อง</option><option value="0">บกพร่อง</option></select></td>
                                        <td><button type="button" class="btn btn-outline-secondary remove" @click="removeRow(id)">ลบแถว</button></td>
                                      </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th colspan="13"><button type="button" @click="addRow" class="btn btn-outline-info-2x add" >เพิ่มผู้ควบคุมการตรวจสอบ</button></th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                    <br>
                      <div class="mb-2 row">
                        <label class="col-sm-12 col-form-label"><b>6. สรุปข้อบกพร่องที่ต้องดำเนินการแก้ไขและข้อแนะนำ</b></label>
                      </div>
                        <div class="mb-2 row">
                      <label class="col-sm-4 col-form-label">สรุปข้อบกพร่องที่ต้องดำเนินการแก้ไขและข้อแนะนำ</label>
                        <div class="col-sm-4">
                            <select class="form-select" v-model="data_27">
                                    <option value="1">ไม่มีข้อบกพร้อง</option>
                                    <option value="0">มีข้อบกพร่อง คือ (กรุณาเลือกรายการในข้อต่อไป)</option>
                            </select>
                        </div>
                      </div>
                        <div class="mb-2 row">
                        <label class="col-sm-4 col-form-label">มีข้อบกพร่องคือ</label>
                        <div class="col-sm-8">
                          <input class="form-control" type="text" v-model="data_28" maxlength="100">
                        </div>
                      </div>
                    <div class="mb-2 row">
                        <label class="col-sm-4 col-form-label">การพิจารณาโทษ(ถ้ามี)</label>
                        <div class="col-sm-8">
                            <div class="col">
                                <div class="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                  <label class="d-block"><input class="radio_animated" type="radio"  v-model="data_29" value="0">ไม่มี</label>
                                  <label class="d-block"><input class="radio_animated" type="radio"  v-model="data_29" value="1">ตักเตือน</label>
                                  <label class="d-block"><input class="radio_animated" type="radio"  v-model="data_29" value="2">ระงับการดำเนินการ</label>
                                  <label class="d-block"><input class="radio_animated" type="radio"  v-model="data_29" value="3">ระงับการดำเนินการจนกว่าการพิจารณาโทษหรือแก้ไขข้อบกพร่องแล้วเสร็จ</label>
                                </div>
                            </div>
                        </div>
                      </div>
                      <div class="mb-2 row">
                        <label class="col-sm-4 col-form-label">เวลาการพิจารณาโทษ(ถ้ามี)</label>
                        <div class=" mb-2 row col-sm-8">
                            <div class="col-md-6 row">
                                <label class="mt-2 col-md-3">ตั้งแต่วันที่</label>
                                <div class="col-md-9">
                                  <VueDatePicker locale="th" v-model="data_43" auto-apply :enable-time-picker="false"/>
                                </div>
                            </div>
                            <div class="col-md-6 row">
                                <label class="mt-2 col-md-3">ถึงวันที่</label>
                                <div class="col-md-9">
                                  <VueDatePicker locale="th" v-model="data_44" auto-apply :enable-time-picker="false"/>
                                </div>
                            </div>
                        </div>
                      </div>
                    <div class="mb-2 row">
                        <div class="mb-2 row col-sm-12">
                      <label class="col-sm-4 col-form-label">หัวหน้าชุดตรวจสอบ</label>
                          <div class="col-sm-4">
                          <multiselect :options="inpection_name_id_value" :searchable="true" v-model="data_30" :custom-label="inpection_name_id_select" placeholder="เลือกหัวหน้าชุดตรวจสอบ" />
                        </div>
                      </div>
                      </div>
                      <div class="mb-2 row">
                        <div class="mb-2 row col-sm-12">
                      <label class="col-sm-4 col-form-label">ผู้ตรวจสอบ</label>
                        <div class="col-sm-4">
                          <multiselect :options="inpection_name_id_value" :searchable="true" v-model="data_32" :custom-label="inpection_name_id_select" placeholder="เลือกผู้ตรวจสอบ" />
                        </div>
                      </div>
                      </div>
                        <div class="mb-2 row">
                        <div class="mb-2 row col-sm-12">
                      <label class="col-sm-4 col-form-label">ผู้ตรวจสอบ</label>
                        <div class="col-sm-4">
                          <multiselect :options="inpection_name_id_value" :searchable="true" v-model="data_34" :custom-label="inpection_name_id_select" placeholder="เลือกผู้ตรวจสอบ" />
                        </div>
                      </div>
                      </div>
                        <div class="mb-2 row">
                        <div class="mb-2 row col-sm-12">
                      <label class="col-sm-4 col-form-label">ผู้ตรวจสอบ</label>
                        <div class="col-sm-4">
                          <multiselect :options="inpection_name_id_value" :searchable="true" v-model="data_36" :custom-label="inpection_name_id_select" placeholder="เลือกผู้ตรวจสอบ" />
                        </div>
                      </div>
                      </div>
                        <div class="mb-2 row">
                        <div class="mb-2 row col-sm-12">
                      <label class="col-sm-4 col-form-label">ผู้ตรวจสอบ</label>
                        <div class="col-sm-4">
                          <multiselect :options="inpection_name_id_value" :searchable="true" v-model="data_38" :custom-label="inpection_name_id_select" placeholder="เลือกผู้ตรวจสอบ" />
                        </div>
                      </div>
                      </div>
                        <div class="mb-2 row">
                        <div class="mb-2 row col-sm-12">
                      <label class="col-sm-4 col-form-label">ผู้ตรวจสอบ</label>
                        <div class="col-sm-4">
                          <multiselect :options="inpection_name_id_value" :searchable="true" v-model="data_40" :custom-label="inpection_name_id_select" placeholder="เลือกผู้ตรวจสอบ" />
                        </div>
                      </div>
                      </div>

                        <div class="mb-2 row">
                        <label class="col-sm-4 col-form-label">วันและเวลาที่ตรวจสอบ</label>
                        <div class="col-sm-4">
                          <VueDatePicker locale="th" v-model="data_42" auto-apply :enable-time-picker="true"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <div class="col-sm-9 offset-sm-3">
                    <button class="btn btn-primary" type="button" @click="adddata()">บันทึกข้อมูล</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script setup>
import { ref, onMounted, reactive, inject } from 'vue';
import { storeAuth } from "../../store/modules/auth";
import axios from "../../axios";

const Swal = inject('$swal')

const rowsDT = ref([]);
const selectDT = ref('');
const type = ref(1); const users_5 = ref(); const data_1  = ref(''); const data_2 = ref(); const data_3 = ref('ถูกต้อง'); const data_4 = ref('ถูกต้อง'); const data_5 = ref('ถูกต้อง'); const data_6 = ref('มี'); const data_7 = ref('ถูกต้อง'); const data_8  = ref('ถูกต้อง');
const data_9 = ref('ถูกต้อง'); const data_10 = ref('ถูกต้อง'); const data_11 = ref('ถูกต้อง'); const data_12 = ref('ถูกต้อง'); const data_13 = ref('ถูกต้อง'); const data_14 = ref('ถูกต้อง'); const data_15 = ref('ถูกต้อง'); const data_16 = ref('ถูกต้อง'); const data_17 = ref('ถูกต้อง'); const data_18 = ref('ถูกต้อง');
const data_19 = ref('ถูกต้อง'); const data_20 = ref('ถูกต้อง'); const data_21 = ref('ถูกต้อง'); const data_22 = ref('ถูกต้อง'); const data_23 = ref('ถูกต้อง'); const data_24 = ref('ถูกต้อง'); const data_25 = ref('ถูกต้อง'); const data_26 = ref('ถูกต้อง'); const data_27 = ref(1); const data_28 = ref();
const data_29 = ref(0);  const data_30 = ref(); const data_32 = ref(); const data_34 = ref(); const data_36 = ref(); const data_38 = ref(); const data_40 = ref(); const data_42 = ref(); const data_43 = ref(); const data_44 = ref();
  
const tro_name_id_value = ref([]);
function tro_name_id_select({license_number,nameaddresscar}) {return `${license_number} / ${nameaddresscar}`}

const inpection_name_id_value = ref([]);
function inpection_name_id_select({first_name,last_name}) {return `${first_name} ${last_name}`}

async function adddata() {
  if(!data_42.value){
    Swal.fire({
        title: "คำเตือน",
        text: "กรุณาเลือกวันและเวลาที่ตรวจสอบ",
        icon: "warning"
    }); 
  }else if(!users_5.value){
    Swal.fire({
        title: "คำเตือน",
        text: "กรุณาเลือกสถานตรวจสภาพรถ",
        icon: "warning"
    });
  }else{
    Swal.fire({
      title: 'ระบบกำลังทำงาน',
      html: 'กรุณารอสักครู่ ...',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading()
    let status_check = '';
    if(data_29.value  == 0){
        status_check = 0;
    }else if(data_29.value == 1){
        status_check = 0;
    }else if(data_29.value == 2){
        status_check = 1;
    }else if(data_29.value == 3){
        status_check = 1;
    }
    const res = await axios.post(`inspection`,{ 
      uid: storeAuth.id,
      gid: storeAuth.group_id,
      tid: 3,
      datas: [
        rowsDT.value,type.value,users_5.value,data_1.value,data_2.value,data_3.value,data_4.value,data_5.value,data_6.value,data_7.value,data_8.value,
        data_9.value,data_10.value,data_11.value,data_12.value,data_13.value,data_14.value,data_15.value,data_16.value,data_17.value,data_18.value,data_19.value,data_20.value,
        data_21.value,data_22.value,data_23.value,data_24.value,data_25.value,data_26.value,data_27.value,data_28.value,data_29.value,data_30.value,data_32.value,data_34.value,
        data_36.value,data_38.value,data_40.value,data_42.value,data_43.value,data_44.value,status_check
      ],
    });
    if(res.data.status == 1){
      Swal.close();
      Swal.fire({
        title: "สำเร็จ",
        icon: "success",
        text: "เพิ่มข้อมูลเรียบร้อยแล้ว",
        showDenyButton: false,
        showCancelButton: false,
        confirmButtonText: "ไปที่ตารางข้อมูล"
      }).then(() => {
        window.location.href = "/user/inspection"
      });
    }else{
      Swal.fire({
        title: "เกิดข้อผิดพลาด",
        text: "เพิ่มข้อมูลไม่สำเร็จ",
        icon: "error"
      }); 
    }
  }
}

async function getsh(){
  Swal.fire({
      title: 'ระบบกำลังทำงาน',
      html: 'กรุณารอสักครู่ ...',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading()

    const res = await axios.post(`getdatash`,{
      uid: storeAuth.id,
      gid: storeAuth.group_id
    });
    if(res.data.status == 1){
      tro_name_id_value.value = res.data.datas.datag4;
      inpection_name_id_value.value = res.data.datas.datag8;
      Swal.close()
    }
}

async function addRow(){
  if(users_5.value){
      selectDT.value = ''
      Swal.fire({
        title: 'ระบบกำลังทำงาน',
        html: 'กรุณารอสักครู่ ...',
        allowEscapeKey: false,
        allowOutsideClick: false,
      });
      Swal.showLoading()
      
      const res = await axios.post(`inspection`,{
        uid: storeAuth.id,
        gid: storeAuth.group_id,
        tid: 0,
        datas: [users_5.value.id]
      });
      if(res.data.status == 1){
        selectDT.value = "<option value=''>กรุณาเลือก</option>"
        for (let i = 0; i < res.data.datas.length; i++) {
          selectDT.value = selectDT.value.concat("<option value='"+ res.data.datas[i].id + "'>" + res.data.datas[i].inspector_num + " / " + res.data.datas[i].first_name + " " + res.data.datas[i].last_name + "</option>")
        }
        rowsDT.value.push({controller:"",statusex:"",actuation:"",diagnosis:""});
        Swal.close()
      }else{
        Swal.fire({
            title: "คำเตือน",
            text: "ไม่สามารถดึงข้อมูลช่างตรวจสภาพของ ตรอ นี้ได้",
            icon: "error"
        }); 
        Swal.close()
      }
  }else{
    Swal.fire({
        title: "คำเตือน",
        text: "กรุณาเลือกสถานตรวจสภาพรถก่อน",
        icon: "warning"
    }); 
  }
}
function removeRow(id){
  rowsDT.value.splice(id, 1);
}

onMounted(() => {
  getsh();
})
</script>